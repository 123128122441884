<div class="activity-container">
  <div class="header">
    <div class="header-content">
      <div class="activity-heading">{{ activity?.activityHeader }}</div>
      <div class="activity-type">
        <span class="activity-type-placeholder"> Activity Type : </span>
        {{ activity?.activityName }}
      </div>
    </div>
    <div class="tag" *ngIf="activity?.locationType">{{ activity?.locationType }}</div>
  </div>
  <div class="content">
    <div class="key-dates">
      <div class="date-name">Planned Start Time</div>
      <div class="date-value">
        {{ activity?.plannedStartDateTime ? (activity?.plannedStartDateTime | date: 'dd MMM yyyy HH:mm') : '---' }}
      </div>
    </div>
    <div class="key-dates">
      <div class="date-name">Actual Start Time</div>
      <div class="date-value">
        {{ activity?.actualStartDateTime ? (activity?.actualStartDateTime | date: 'dd MMM yyyy HH:mm') : '---' }}
      </div>
    </div>
    <div class="key-dates">
      <div class="date-name">Planned End Time</div>
      <div class="date-value">
        {{ activity?.plannedEndDateTime ? (activity?.plannedEndDateTime | date: 'dd MMM yyyy HH:mm') : '---' }}
      </div>
    </div>
    <div class="key-dates">
      <div class="date-name">Actual End Time</div>
      <div class="date-value">
        {{ activity?.actualEndDateTime ? (activity?.actualEndDateTime | date: 'dd MMM yyyy HH:mm') : '---' }}
      </div>
    </div>
  </div>
  <div class="footer">
    <button mat-stroked-button color="primary" disabled>View Optional Work Orders</button>
    <button mat-stroked-button class="edit" color="primary" (click)="edit()">Edit</button>
    <button mat-stroked-button class="delete" color="warn" (click)="delete()">Delete</button>
  </div>
</div>
<!--TODO: disabled for now. enable in the next iteration of dev-->
<div
  class="add-activity"
  [class.add-activity-disabled]="true"
  (click)="addActivity()"
  tabindex="0"
  (keydown.enter)="addActivity()"
  (keydown.space)="addActivity()">
  <div class="add-icon">
    <mat-icon>add</mat-icon>
  </div>
  <div class="text">Add Activity</div>
  <div class="horizontal-line"></div>
</div>
