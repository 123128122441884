import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FieldType } from '@ngx-formly/core';
import { Select } from '@ngxs/store';
import { Apollo, gql } from 'apollo-angular';
import { filter, forkJoin, map, Observable, of, switchMap, take } from 'rxjs';
import { ServiceCard } from '../../models/service-card/service-card.model';
import { WizardState } from '../../store/wizard.state';

@Component({
  selector: 'hmt-route-details-summary',
  templateUrl: './route-details-summary.component.html',
  styleUrl: './route-details-summary.component.scss',
})
export class RouteDetailsSummaryComponent extends FieldType implements OnInit {
  @Select(WizardState.getServiceCard) serviceCard$: Observable<ServiceCard>;

  locations: { _id: string; locationName: string }[] = [];
  countries: { _id: string; countryName: string }[] = [];
  activities: { _id: string; activityName: string; locationId: string }[] = [];
  constructor(
    private apollo: Apollo,
    // ChangeDetectorRef is used to trigger change detection in the template only when the locations array is updated
    private cdr: ChangeDetectorRef
  ) {
    super();
  }

  ngOnInit(): void {
    this.serviceCard$
      .pipe(
        filter(serviceCard => !!serviceCard),
        take(1),
        switchMap(serviceCard => {
          const routingDetails = serviceCard?.routingDetails;

          let locationIds = [];
          //TODO: remove this harcoded condition
          if (serviceCard?.serviceId === 'AIR-FREIGHT') {
            locationIds = [routingDetails?.loadingLocation, routingDetails?.unloadingLocation];
          } else if (serviceCard?.serviceId === 'OCEAN-FREIGHT') {
            locationIds = [routingDetails?.loadingLocation, routingDetails?.unloadingLocation];
          }

          locationIds = [...locationIds, routingDetails?.loadingTerminal, routingDetails?.unloadingTerminal];
          locationIds = locationIds.filter(id => id);
          const countryIds = [routingDetails?.countryOfOrigin, routingDetails?.countryOfDestination].filter(id => id);

          this.activities = serviceCard?.activities.map(activity => ({
            _id: activity.activityId,
            activityName: activity.activityName,
            locationId: activity.locationId,
          }));

          return forkJoin({
            locations: locationIds.length > 0 ? this.getLocationsByIds(locationIds) : of([]),
            countries: countryIds.length > 0 ? this.getCountriesByIds(countryIds) : of([]),
          });
        })
      )
      .subscribe(({ locations, countries }) => {
        this.locations = locations;
        this.countries = countries;
        this.cdr.detectChanges();
      });
  }

  getLocationsByIds(locationIds: string[]): Observable<{ _id: string; locationName: string }[]> {
    return this.apollo
      .query({
        query: gql`
          query FindLocationsByIds($locationIds: [String!]!) {
            findLocationsByIds(locationIds: $locationIds) {
              locationName
              _id
            }
          }
        `,
        variables: {
          locationIds,
        },
      })
      .pipe(
        filter(result => result.data['findLocationsByIds']),
        take(1),
        map(result => result.data['findLocationsByIds'])
      );
  }

  getCountriesByIds(countryIds: string[]): Observable<{ _id: string; countryName: string }[]> {
    return this.apollo
      .query({
        query: gql`
          query GetCountriesByIds($countryIds: [String!]!) {
            getCountriesByIds(countryIds: $countryIds) {
              _id
              countryName
            }
          }
        `,
        variables: {
          countryIds,
        },
      })
      .pipe(
        filter(result => result.data['getCountriesByIds']),
        take(1),
        map(result => result.data['getCountriesByIds'])
      );
  }

  getLocationName(locationId: string): string {
    const location = this.locations?.find(location => location._id === locationId);
    return location ? location.locationName : '';
  }

  getCountryName(countryId: string): string {
    const country = this.countries?.find(country => country._id === countryId);
    return country ? country.countryName : '';
  }
}
