import { Component, EventEmitter, Inject, OnDestroy, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { PageEvent } from '@angular/material/paginator';
import { DEFAULT_DEBOUNCE_TIME, PAGE_SIZE } from '@shared/constants';
import { Pageable } from '@shared/models/pageable.model';
import { EntityMap } from '@shared/types';
import { debounceTime, distinctUntilChanged, Observable, startWith, Subject, takeUntil, tap } from 'rxjs';
import { Order } from '../../models/order.model';
import { SelectableOrder } from '../../models/selecrtable-order.model';
@Component({
  selector: 'hmt-order-selector-drawer',
  templateUrl: './order-selector-drawer.component.html',
  styleUrl: './order-selector-drawer.component.scss',
})
export class OrderSelectorDrawerTsComponent implements OnInit, OnDestroy {
  @Output() selectedOrders = new EventEmitter();
  @Output() selectedFirstOrder = new EventEmitter<{ order: Order; searchText: string }>();
  @Output() pageEvent = new EventEmitter<{ pageEvent: PageEvent; searchText: string }>();
  destroy$ = new Subject<void>();
  orderPageable: Pageable<SelectableOrder>;
  //declare a variable to store the first selected order because based on the 1st order selected, similar orders
  //Will be filtered
  firstSelectedOrder: SelectableOrder;
  searchControl = new FormControl('');
  selectedOrdersAmongAllPages: EntityMap<string, SelectableOrder> = {};
  selectAllChecked: boolean = false;

  constructor(
    private dialogRef: MatDialogRef<OrderSelectorDrawerTsComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      categorizedOrders: Observable<Pageable<Order>>;
      shipmentOrders: Order[];
    }
  ) {
    if (data && data.categorizedOrders) {
      this.transformToSelectableOrders();
    }
  }

  ngOnInit(): void {
    this.listenToSearchChanges();
  }

  listenToSearchChanges(): void {
    this.searchControl.valueChanges
      .pipe(
        takeUntil(this.destroy$),
        distinctUntilChanged(),
        startWith(''),
        debounceTime(DEFAULT_DEBOUNCE_TIME),
        tap(value =>
          this.pageEvent.emit({
            pageEvent: { pageIndex: 0, pageSize: PAGE_SIZE, length: 0 },
            searchText: value,
          })
        )
      )
      .subscribe();
  }

  saveSelectedOrders() {
    const selectedOrders = this.orderPageable?.items.filter(order => order.selected);
    this.selectedOrders.emit(selectedOrders);
    this.dialogRef.close();
  }

  checkedOrder(checked: boolean, order: Order) {
    if (checked && !this.data?.shipmentOrders.length && !this.firstSelectedOrder?.selected) {
      this.firstSelectedOrder = order;
      this.firstSelectedOrder.selected = true;
      this.selectedFirstOrder.emit({ order: order, searchText: this.searchControl?.value });
    }

    if (this.firstSelectedOrder?._id === order._id && this.firstSelectedOrder?.selected && !checked) {
      this.firstSelectedOrder.selected = false;
      this.selectedFirstOrder.emit({ order: null, searchText: this.searchControl?.value });
    }
  }

  cancel(): void {
    this.dialogRef.close();
  }

  transformToSelectableOrders(): void {
    this.data.categorizedOrders
      .pipe(
        takeUntil(this.destroy$),
        tap((orders: Pageable<Order>) => {
          if (orders && orders?.items) {
            this.orderPageable = {
              ...orders,
              items: orders.items.map(order => {
                if (this.firstSelectedOrder && order._id === this.firstSelectedOrder._id) {
                  return { ...order, selected: this.firstSelectedOrder.selected };
                }
                return { ...order, selected: false };
              }),
            };
            if (this.selectAllChecked) {
              this.orderPageable.items = this.orderPageable.items.map(order => {
                this.selectedOrdersAmongAllPages[order._id] = order;
                return { ...order, selected: true };
              });
            }
          }
        })
      )
      .subscribe();
  }

  saveContainers(): void {}

  selectAll(checked: boolean): void {
    this.selectAllChecked = checked;
    this.orderPageable.items = this.orderPageable.items.map(order => {
      if (checked) {
        this.selectedOrdersAmongAllPages[order._id] = order;
      } else {
        delete this.selectedOrdersAmongAllPages[order._id];
      }
      return { ...order, selected: checked };
    });
  }

  handlePageEvent(event: PageEvent) {
    this.pageEvent.emit({ pageEvent: event, searchText: this.searchControl?.value });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
