<div class="tendered-orders-container">
  <div class="header">
    <span class="header-title">Orders</span>
    <mat-form-field appearance="outline">
      <!--TODO: enable when backend is ready-->
      <input matInput placeholder="Search" disabled="true" />
    </mat-form-field>
  </div>
  <div class="content">
    <ng-container *ngIf="serviceConfigs && smartenedJobFile === false">
      <div class="service-content">
        <div class="row-1 row">
          <div class="col col-1">
            <div>Shipment Methods</div>
            <div class="col-content">
              <div class="shipment-type-container">
                <div class="shipment-type-col">
                  <div class="title">Mode</div>
                  <div class="mode-content"></div>
                </div>
              </div>
            </div>
          </div>
          <div class="col col-2">
            <div>Services Requested</div>
            <div class="col-content">
              <div *ngFor="let serviceId of (currentTender$ | async).tenderMetaData.unSmartenedMetadata.serviceIds">
                <div class="service">
                  {{ getServiceName(serviceId) }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ng-container>
    @for (order of orders$ | async; track order._id) {
      <hmt-order-card [order]="order" [viewOnly]="true" [hideCheckBox]="true"></hmt-order-card>
    }
  </div>
</div>
