import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AgGridAngular } from 'ag-grid-angular'; // Angular Data Grid Component
import { ColDef, GridApi, GridOptions, GridReadyEvent } from 'ag-grid-community';

@Component({
  selector: 'hmt-dynamic-table',
  templateUrl: './dynamic-table.component.html',
  styleUrl: './dynamic-table.component.scss',
  standalone: true,
  imports: [AgGridAngular],
})
export class DynamicTableComponent implements OnInit {
  gridOptions: GridOptions = {
    defaultColDef: {
      width: 150,
      resizable: true,
    },
    onRowDataUpdated: this.onRowDataChanged.bind(this),
    editType: 'fullRow',
    singleClickEdit: true,
    stopEditingWhenCellsLoseFocus: true,
  };

  rowData: any[] = [];
  gridApi!: GridApi;
  editType: 'fullRow' | undefined = 'fullRow';

  @Input() data = [];
  @Input() editable = false;
  @Input() sortable = true;
  @Input() columnDefs: ColDef[] = [];
  @Output() savedData = new EventEmitter<any>();

  constructor() {}

  ngOnInit(): void {
    if (this.editable) {
      this.addEmptyRow();
    }

    if (!this.columnDefs || !this.columnDefs.length) {
      this.columnDefs = this.generateColumnDefs(this.data);
    }

    this.rowData = this.generateRowData(this.data);
  }

  generateColumnDefs(data: any[]) {
    const columnDefs = [];
    if (data.length) {
      const firstRow = data[0];
      for (const key in firstRow) {
        if (Object.prototype.hasOwnProperty.call(firstRow, key)) {
          const columnDef = {
            headerName: key,
            field: key,
            editable: this.editable,
            sortable: this.sortable,
          };
          columnDefs.push(columnDef);
        }
      }
    }
    return columnDefs;
  }

  generateRowData(data: any[]) {
    const rowData = [];
    for (const record of data) {
      const row = {};
      for (const key in record) {
        if (Object.prototype.hasOwnProperty.call(record, key)) {
          row[key] = record[key];
        }
      }
      rowData.push(row);
    }
    return rowData;
  }

  onRowDataChanged() {
    if (this.editable) {
      this.addEmptyRow();
    }
  }

  addEmptyRow() {
    const lastRow = this.rowData[this.rowData.length - 1];
    if (this.shouldAddEmptyRow(lastRow)) {
      this.rowData = [...this.rowData, {}];
    } else {
      //editing mod
    }
  }

  shouldAddEmptyRow(lastRow) {
    const isRowDataEmpty = this.rowData.length === 0;
    const isLastRowEmpty = lastRow && Object.values(lastRow).some(value => value !== '');
    return (isRowDataEmpty && this.editable) || isLastRowEmpty;
  }

  onRowValueChanged() {
    if (this.editable) {
      this.addEmptyRow();
      this.savedData.emit(this.rowData);
    }
  }

  onGridReady(params: GridReadyEvent) {
    this.gridApi = params.api;
  }

  save() {
    //TODO: implement save when integrating
  }
}
