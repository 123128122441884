import { Component, OnDestroy, OnInit } from '@angular/core';
import { FieldWrapper } from '@ngx-formly/core';
import { Select } from '@ngxs/store';
import { Observable, Subject, takeUntil, tap } from 'rxjs';
import { JobFile } from '../../models/job-file.model';
import { WizardState } from '../../store/wizard.state';

@Component({
  selector: 'hmt-general-details',
  templateUrl: './general-details.component.html',
  styleUrl: './general-details.component.scss',
})
export class GeneralDetailsComponent extends FieldWrapper implements OnInit, OnDestroy {
  @Select(WizardState.getJobFile) jobFile$: Observable<JobFile>;
  private $destroy: Subject<boolean> = new Subject();

  jobFileName = {
    key: 'Job File Name',
    value: '--',
  };
  jobType = {
    key: 'Job Type',
    value: '--',
  };

  jobFileGroup = {
    key: 'Job File Group',
    value: '--',
  };

  JobReference = {
    key: 'Job Reference Number',
    value: '--',
  };

  customerReferenceNumber = {
    key: 'Customer Reference Number',
    value: '--',
  };

  ngOnInit(): void {
    this.jobFile$
      .pipe(
        takeUntil(this.$destroy),
        tap((jobFile: JobFile) => {
          this.jobFileName.value = jobFile?.title || '--';
          this.jobType.value = jobFile?.workflow?.name || '--';
          this.jobFileGroup.value = jobFile?.jobGroupName || '--';
          this.JobReference.value = jobFile?.jobRefId || '--';
          this.customerReferenceNumber.value = jobFile?.customerReferenceNo || '--';
        })
      )
      .subscribe();
  }
  ngOnDestroy(): void {
    this.$destroy.next(true);
    this.$destroy.complete();
  }
}
