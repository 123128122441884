<div class="add-activity">
  <div class="header">
    <div class="close-button">
      <mat-icon>close</mat-icon>
    </div>
    <div class="title">{{ data?.activity ? 'Edit Activity' : 'Add Activity' }}</div>
  </div>
  <form [formGroup]="activityForm" (ngSubmit)="onSubmit()">
    <div class="content">
      <div class="row-1">
        <mat-form-field class="input-field" appearance="outline">
          <mat-label>Activity Type</mat-label>
          <mat-select formControlName="activityType" (selectionChange)="selectService($event.value)">
            @for (service of additionalServices; track service.id) {
              <mat-option [value]="service">{{ service.name }}</mat-option>
            }
          </mat-select>
        </mat-form-field>
      </div>
      <div class="location-details" *ngIf="activity?.locationId">
        <div class="title">Location Details</div>
        <!--TODO: enable in coming iterations-->
        <!-- <div class="radio-group">
          <mat-radio-group formControlName="locationType">
            <mat-radio-button value="1">Partner</mat-radio-button>
            <mat-radio-button value="2">Public</mat-radio-button>
            <mat-radio-button value="3">Other</mat-radio-button>
            <mat-radio-button value="4">External</mat-radio-button>
          </mat-radio-group>
        </div> -->
        <div class="location">
          <!--TODO: convert to autocomplete in coming iteration-->
          <mat-form-field class="input-field" appearance="outline">
            <mat-label>From</mat-label>
            <input type="text" matInput formControlName="fromLocation" [disabled]="true" />
          </mat-form-field>
          <!--TODO: convert to autocomplete in coming iteration-->
          <mat-form-field class="input-field" appearance="outline">
            <mat-label>To</mat-label>
            <input type="text" matInput formControlName="toLocation" [disabled]="true" />
          </mat-form-field>
        </div>
      </div>
      <div class="schedule-information">
        <div class="title">Schedule Information</div>
        <div class="row row-1">
          <mat-form-field appearance="outline">
            <mat-label>Planned Start Time</mat-label>
            <input matInput formControlName="plannedStartTime" [matDatepicker]="plannedStarTimePicker" />
            <mat-datepicker-toggle matIconSuffix [for]="plannedStarTimePicker"></mat-datepicker-toggle>
            <mat-datepicker #plannedStarTimePicker></mat-datepicker>
          </mat-form-field>
          <mat-form-field appearance="outline">
            <mat-label>Actual Start Time</mat-label>
            <input matInput formControlName="actualStartTime" [matDatepicker]="actualStartTimePicker" />
            <mat-datepicker-toggle matIconSuffix [for]="actualStartTimePicker"></mat-datepicker-toggle>
            <mat-datepicker #actualStartTimePicker></mat-datepicker>
          </mat-form-field>
        </div>
        <div class="row">
          <mat-form-field appearance="outline">
            <mat-label>Planned End Time</mat-label>
            <input matInput formControlName="plannedEndTime" [matDatepicker]="plannedEndPicker" />
            <mat-datepicker-toggle matIconSuffix [for]="plannedEndPicker"></mat-datepicker-toggle>
            <mat-datepicker #plannedEndPicker></mat-datepicker>
          </mat-form-field>
          <mat-form-field appearance="outline">
            <mat-label>Actual End Time</mat-label>
            <input matInput formControlName="actualEndTime" [matDatepicker]="actualEndTimePicker" />
            <mat-datepicker-toggle matIconSuffix [for]="actualEndTimePicker"></mat-datepicker-toggle>
            <mat-datepicker #actualEndTimePicker></mat-datepicker>
          </mat-form-field>
        </div>
      </div>
      <div class="tasks">
        <div class="title">Task To Be Done</div>
        <div class="task-table">
          <div class="table-titles">
            <div class="column tasks">Tasks</div>
            <div class="column update">Update Required</div>
            <div class="column hours">H</div>
            <div class="column minutes">M</div>
          </div>
          <div formArrayName="workOrders">
            <div
              class="row"
              *ngFor="let task of activityForm.get('workOrders')['controls']; let i = index"
              [formGroupName]="i">
              <div class="column tasks">
                <mat-checkbox formControlName="required">{{ task.get('workOrderName').value }}</mat-checkbox>
              </div>
              <div class="column update">
                <mat-checkbox formControlName="updateRequired"></mat-checkbox>
              </div>
              <div class="column hours">
                <input
                  type="text"
                  [ngModel]="minutesToHoursAndMinutes(task.get('durationInMinutes').value)?.hours"
                  (ngModelChange)="updateDuration(i, $event, 'hours')"
                  [ngModelOptions]="{ standalone: true }" />
              </div>
              <div class="column minutes">
                <input
                  type="text"
                  [ngModel]="minutesToHoursAndMinutes(task.get('durationInMinutes').value)?.minutes"
                  (ngModelChange)="updateDuration(i, $event, 'minutes')"
                  [ngModelOptions]="{ standalone: true }" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="footer">
      <button mat-raised-button type="button" (click)="dialogRef.close()">Cancel</button>
      <button mat-raised-button color="primary" type="submit">Update</button>
    </div>
  </form>
</div>
