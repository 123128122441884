import { Component, inject, OnInit } from '@angular/core';
import { FieldWrapper } from '@ngx-formly/core';
import { Store } from '@ngxs/store';
import { map, take } from 'rxjs';
import { FetchAllOrders } from '../../store/wizard.actions';
import { WizardState } from '../../store/wizard.state';

@Component({
  selector: 'hmt-unsmartened-orders-container',
  templateUrl: './unsmartened-orders-container.component.html',
  styleUrl: './unsmartened-orders-container.component.scss',
})
export class UnsmartenedOrdersContainerComponent extends FieldWrapper implements OnInit {
  orders$ = inject(Store).select(WizardState.getOrders);
  jobFile$ = inject(Store).select(WizardState.getJobFile);

  private readonly store$: Store = inject(Store);

  smartenedJobFile: boolean;

  ngOnInit(): void {
    this.jobFile$
      .pipe(
        take(1),
        map(jobFile => {
          this.store$.dispatch(new FetchAllOrders(jobFile.jobRefId));
          this.smartenedJobFile = jobFile.smartened;
        })
      )
      .subscribe();
  }
}
