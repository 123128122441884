import { Component, Inject } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Order } from '../../models/order.model';

@Component({
  selector: 'hmt-view-order-cards',
  templateUrl: './view-order-cards.component.html',
  styleUrl: './view-order-cards.component.scss',
})
export class ViewOrderCardsComponent {
  searchControl = new FormControl();
  constructor(
    public dialogRef: MatDialogRef<ViewOrderCardsComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      orders: Order[];
    }
  ) {}

  close(): void {
    this.dialogRef.close();
  }
}
