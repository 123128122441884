<div class="container">
  <div class="header">
    <mat-icon (click)="close()">close</mat-icon>
  </div>
  <div class="content">
    <div class="icon-container">
      <img src="assets/icons/question_blue.svg" alt="question mark" />
    </div>
    <div class="confirmation">
      <div class="title">Shipment Creation</div>
      <div class="message">Your orders have been added successfully. What would you like to do next?</div>
    </div>
    <div class="button-group-container">
      <button mat-raised-button color="primary" (click)="createShipmentAction('smartened')">Create Shipment</button>
      <button
        mat-raised-button
        [disabled]="data?.parentJobRefId"
        (click)="continueToAssignServicesAction('unsmartened')">
        Continue to Assign Services
      </button>
    </div>
  </div>
</div>
