import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FormlyFieldConfig, FormlyFormOptions } from '@ngx-formly/core';
import { JsonSchemaService } from 'app/json-schema-forms/services/json-schema.service';

@Component({
  selector: 'hmt-service-view-tab',
  templateUrl: './service-view-tab.component.html',
  styleUrl: './service-view-tab.component.scss',
})
export class ServiceViewTabComponent {
  @Input() fields: FormlyFieldConfig[] = [];
  @Input() model = {};
  @Input() styles: any = '';
  @Input() options: FormlyFormOptions = {};
  @Input() form: FormGroup = new FormGroup({});

  constructor(readonly jsonSchemaService: JsonSchemaService) {}

  submit() {}

  get styleObject() {
    return this.jsonSchemaService.cssStringToObject(this.styles);
  }

  getStylesClasses(id: string) {
    if (!this.styles || !this.styles[id]) {
      return '';
    }
    return this.styles[id];
  }
}
