import { Component, inject, OnDestroy, OnInit } from '@angular/core';
import { FieldType } from '@ngx-formly/core';
import { Select } from '@ngxs/store';
import { Apollo, gql } from 'apollo-angular';
import { Observable, Subject, take } from 'rxjs';
import { ServiceCard } from '../../models/service-card/service-card.model';
import { WizardState } from '../../store/wizard.state';

@Component({
  selector: 'hmt-service-transhipment-wizard',
  templateUrl: './service-transhipment-wizard.component.html',
  styleUrl: './service-transhipment-wizard.component.scss',
})
export class ServiceTranshipmentWizardComponent extends FieldType implements OnInit, OnDestroy {
  @Select(WizardState.getServiceCard)
  serviceCard$: Observable<ServiceCard>;
  destroy$ = new Subject<void>();
  private apollo = inject(Apollo);
  shipmentModes: { key: string; value: string }[] = [];

  ngOnInit(): void {
    this.apollo
      .query({
        query: gql`
          {
            shipmentModes {
              key
              value
            }
          }
        `,
      })
      .pipe(take(1))
      .subscribe(({ data }) => {
        this.shipmentModes = data['shipmentModes'];
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
