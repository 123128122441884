<div *ngFor="let item of selectableOrders">
  <div class="order-card">
    <hmt-order-card
      (editOrderAction)="editOrder($event)"
      (deleteOrderAction)="deleteOrder($event)"
      [hideCheckBox]="true"
      [order]="item"
      [viewOnly]="!!parentJobRefId"></hmt-order-card>
  </div>
</div>
