import { Component, inject, Input, OnDestroy } from '@angular/core';
import { DialogHandlerService } from '@core/services/dialog-handler.service';

import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { GraphqlClientService } from '@core/services/graphql-client.service';
import { Store } from '@ngxs/store';
import { ConfirmationDialogComponent } from '@shared/components/confirmation-dialog/confirmation-dialog.component';
import { Subject, takeUntil } from 'rxjs';
import { Activity } from '../../models/activity.model';
import { AddHmtActivity, RemoveHmtActivity, UpdateActivity } from '../../store/wizard.actions';
import { AddActivityComponent } from '../add-activity/add-activity.component';

@Component({
  selector: 'hmt-activity',
  templateUrl: './activity.component.html',
  styleUrl: './activity.component.scss',
})
export class ActivityComponent implements OnDestroy {
  @Input() activity: Activity;
  @Input() plannedServiceId: string;
  @Input() workflowDefId: string;
  @Input() previousActivityId: string;

  private destroy$ = new Subject<void>();
  private readonly dialogHandlerService = inject(DialogHandlerService);
  private store$ = inject(Store);
  private readonly matDialog = inject(MatDialog);
  private readonly graphqlClient = inject(GraphqlClientService);

  edit(): void {
    const dialoRef: MatDialogRef<AddActivityComponent> = this.dialogHandlerService.openDialog(
      AddActivityComponent,
      { activity: this.activity, workflowDefId: this.workflowDefId, editMode: true },
      {
        backdropClass: 'backdropBackground',
        width: '800px',
        height: '100%',
        panelClass: 'custom-dialog-container',
      }
    );
    dialoRef.componentInstance.activityUpdated.pipe(takeUntil(this.destroy$)).subscribe((activity: Activity) => {
      this.store$.dispatch(new UpdateActivity(activity, this.plannedServiceId));
    });
  }

  addActivity(): void {
    const dialogRef: MatDialogRef<AddActivityComponent> = this.dialogHandlerService.openDialog(
      AddActivityComponent,
      {
        previousActivityId: this.previousActivityId,
        workflowDefId: this.workflowDefId,
      },
      {
        backdropClass: 'backdropBackground',
        width: '800px',
        height: '100%',
      }
    );
    dialogRef.componentInstance.activityAdded.pipe(takeUntil(this.destroy$)).subscribe((activity: Activity) => {
      this.store$.dispatch(new AddHmtActivity(activity, this.plannedServiceId, this.previousActivityId));
    });
  }

  delete(): void {
    this.matDialog.open(ConfirmationDialogComponent, {
      data: {
        message: 'Are you sure you want to delete this activity?',
        confirmButtonText: 'Delete',
        cancelButtonText: 'Cancel',
        showCancel: true,
        onConfirmCallback: () => {
          this.store$.dispatch(new RemoveHmtActivity(this.activity._id, this.plannedServiceId));
        },
      },
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
