import { Component, OnInit } from '@angular/core';
import { FieldType } from '@ngx-formly/core';
import { Select } from '@ngxs/store';
import { ColDef } from 'ag-grid-community';
import { Observable, filter, take, tap } from 'rxjs';
import { PackingList } from '../../models/packing-list.model';
import { ServiceCard } from '../../models/service-card/service-card.model';
import { WizardState } from '../../store/wizard.state';

@Component({
  selector: 'hmt-order-packing-list-table',
  templateUrl: './order-packing-list-table.component.html',
  styleUrl: './order-packing-list-table.component.scss',
})
export class OrderPackingListTableComponent extends FieldType implements OnInit {
  transformedPackageList: PackingList<number, string>[] = [];
  @Select(WizardState.getOrders) serviceCard$: Observable<ServiceCard>;

  //TODO: get this mapping from the config
  columnNames: { header: string; field: string; type?: string; cellEditor?: string; cellEditorParams?: any }[] = [
    { header: 'ItemRef Id', field: 'itemRefId', type: 'text', cellEditor: 'agTextCellEditor' },
    { header: 'Commodity', field: 'commodity', type: 'text', cellEditor: 'agTextCellEditor' },
    { header: 'HS Codes', field: 'hsCodes', type: 'text', cellEditor: 'agTextCellEditor' },
    { header: 'Description Of Cargo', field: 'descriptionOfCargo', type: 'text', cellEditor: 'agTextCellEditor' },
    { header: 'Packaging Type', field: 'packagingType', type: 'text', cellEditor: 'agTextCellEditor' },
    { header: 'No of Units', field: 'noOfUnits', type: 'number', cellEditor: 'agNumberCellEditor' },
    { header: 'Dimension(H)', field: 'dimensionH', type: 'number', cellEditor: 'agNumberCellEditor' },
    { header: 'Dimension(W)', field: 'dimensionW', type: 'number', cellEditor: 'agNumberCellEditor' },
    { header: 'Dimension(L)', field: 'dimensionL', type: 'number', cellEditor: 'agNumberCellEditor' },
    { header: 'Weight Per Unit', field: 'weightPerUnit', type: 'number', cellEditor: 'agNumberCellEditor' },
    { header: 'Gross Weight', field: 'grossWeight', type: 'number', cellEditor: 'agNumberCellEditor' },
    { header: 'Net Weight', field: 'netWeight', type: 'number', cellEditor: 'agNumberCellEditor' },
    { header: 'Chargeable Weight', field: 'chargeableWeight', type: 'number', cellEditor: 'agNumberCellEditor' },
    { header: 'Volume', field: 'volume', type: 'number', cellEditor: 'agNumberCellEditor' },
    {
      header: 'Dangerous Cargo',
      field: 'dangerousCargo',
      cellEditor: 'agSelectCellEditor',
      cellEditorParams: { values: ['Yes', 'No'] },
    },
    {
      header: 'Special Cargo',
      field: 'specialRequirements',
      cellEditor: 'agSelectCellEditor',
      cellEditorParams: { values: ['Yes', 'No'] },
    },
  ];
  columns: ColDef[] = [];

  constructor() {
    super();
  }

  ngOnInit(): void {
    this.generateColDefs();
    if (this.model && this.model.packingList) {
      this.transformedPackageList = this.modelToViewTransformer(this.model.packingList);
    }
  }

  generateColDefs(): void {
    this.columnNames.forEach(column => {
      this.columns.push({
        headerName: column.header,
        field: column.field,
        editable: true,
        cellDataType: column.type,
        cellEditor: column.cellEditor,
        cellEditorParams: column.cellEditorParams,
        valueSetter: params => {
          if (params.newValue) {
            params.data[params.colDef.field] = params.newValue;
            return true;
          }
          return false;
        },
        cellClassRules: {
          'cell-error': params => !params.value, // Add error class if value is empty
        },
      });
    });
  }

  constructPackingList() {
    this.serviceCard$
      .pipe(
        filter(serviceCard => !!serviceCard),
        take(1),
        tap(serviceCard => {
          if (serviceCard && serviceCard.packingLists) {
            this.transformedPackageList = this.modelToViewTransformer(serviceCard.packingLists);
            console.log(this.transformedPackageList);
          }
        })
      )
      .subscribe();
  }

  modelToViewTransformer(packingList: PackingList<number, boolean>[]): PackingList<number, string>[] {
    return packingList.map(packingList => {
      return {
        itemRefId: packingList?.itemRefId ?? '',
        commodity: packingList?.commodity ?? '',
        hsCodes: packingList?.hsCodes ?? '',
        descriptionOfCargo: packingList?.descriptionOfCargo ?? '',
        packagingType: packingList?.packagingType ?? '',
        noOfUnits: packingList?.noOfUnits ?? 0,
        dimensionH: packingList?.dimensionH ?? 0,
        dimensionW: packingList?.dimensionW ?? 0,
        dimensionL: packingList?.dimensionL ?? 0,
        weightPerUnit: packingList?.weightPerUnit ?? 0,
        grossWeight: packingList?.grossWeight ?? 0,
        netWeight: packingList?.netWeight ?? 0,
        chargeableWeight: packingList?.chargeableWeight ?? 0,
        volume: packingList?.volume ?? 0,
        dangerousCargo: this.booleanToYesNo(packingList?.dangerousCargo),
        specialRequirements: this.booleanToYesNo(packingList?.specialRequirements),
      };
    });
  }

  booleanToYesNo(value: boolean): string {
    return value ? 'Yes' : 'No';
  }

  patchDataToModel(packingList: PackingList<string, string>[]): void {
    if (!packingList || !packingList?.length) {
      return;
    }
    const transformedPackingList = packingList
      .filter(item => Object.keys(item).length > 0)
      .map(item => {
        return {
          itemRefId: item.itemRefId ?? '',
          commodity: item.commodity ?? '',
          hsCodes: item.hsCodes ?? '',
          descriptionOfCargo: item.descriptionOfCargo ?? '',
          packagingType: item.packagingType ?? '',
          noOfUnits: parseInt(item?.noOfUnits) ?? 0,
          dimensionH: parseInt(item.dimensionH) ?? 0,
          dimensionW: parseInt(item.dimensionW) ?? 0,
          dimensionL: parseInt(item.dimensionL) ?? 0,
          weightPerUnit: parseInt(item.weightPerUnit) ?? 0,
          grossWeight: parseInt(item.grossWeight) ?? 0,
          netWeight: parseInt(item.netWeight) ?? 0,
          chargeableWeight: parseInt(item.chargeableWeight) ?? 0,
          volume: parseInt(item.volume) ?? 0,
          dangerousCargo: item?.dangerousCargo === 'Yes',
          specialRequirements: item?.specialRequirements === 'Yes',
        };
      });
    if (transformedPackingList.length > 0) {
      this.updateCargoDetails(transformedPackingList);
    }
  }

  updateCargoDetails(transformedPackingList): void {
    this.field.form.controls['packingList'].patchValue(transformedPackingList);
    this.field.form.controls['row-1'].controls['grossWeight'].patchValue(
      isNaN(transformedPackingList.reduce((acc, curr) => acc + curr.grossWeight, 0))
        ? 0
        : transformedPackingList.reduce((acc, curr) => acc + curr.grossWeight, 0)
    );
    this.field.form.controls['row-1'].controls['netWeight'].patchValue(
      isNaN(transformedPackingList.reduce((acc, curr) => acc + curr.netWeight, 0))
        ? 0
        : transformedPackingList.reduce((acc, curr) => acc + curr.netWeight, 0)
    );
    this.field.form.controls['row-2'].controls['chargeableWeight'].patchValue(
      isNaN(transformedPackingList.reduce((acc, curr) => acc + curr.chargeableWeight, 0))
        ? 0
        : transformedPackingList.reduce((acc, curr) => acc + curr.chargeableWeight, 0)
    );

    this.field.form.controls['row-3'].controls['dangerousCargo'].patchValue(
      transformedPackingList.some(item => item.dangerousCargo)
    );

    this.field.form.controls['row-3'].controls['specialCargo'].patchValue(
      transformedPackingList.some(item => item.specialRequirements)
    );
  }
}
