<div class="assign-services-container">
  <div class="header">
    <mat-checkbox [(ngModel)]="allSelected" (change)="selectAll($event)">
      <span class="header-title">What Services Do You Need ?</span>
    </mat-checkbox>
  </div>
  <div class="content">
    @for (serviceConfig of serviceConfigs; track serviceConfig._id) {
      <div class="service">
        <mat-checkbox [(ngModel)]="serviceConfig.selected" (change)="checkService(serviceConfig)">
          <span class="header-title">{{ serviceConfig.serviceName }}</span>
        </mat-checkbox>
      </div>
    }
  </div>
</div>
