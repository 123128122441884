<div class="general-details-container">
  <div class="col-1">
    <img src="./assets/icons/globe.svg" alt="home icon" />
  </div>
  <div class="col-2">
    <div class="row-1">General Details</div>
    <div class="general-details-grid">
        <hmt-key-value [keyValue]="jobFileName" style="width: 100%"></hmt-key-value>
      <hmt-key-value [keyValue]="jobType" style="width: 100%"></hmt-key-value>

      <hmt-key-value [keyValue]="jobFileGroup" style="width: 100%"></hmt-key-value>
      <hmt-key-value [keyValue]="JobReference" style="width: 100%"></hmt-key-value>
      <hmt-key-value [keyValue]="customerReferenceNumber" style="width: 100%"></hmt-key-value>
    </div>
  </div>
</div>
