import { Component, inject, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { FieldType } from '@ngx-formly/core';
import { Store } from '@ngxs/store';
import { AIR_FREIGHT } from '@shared/constants';
import { Subject, take, tap } from 'rxjs';
import { JobFile } from '../../models/job-file.model';
import { ShipmentMethodDetail } from '../../models/shipment-method-details';
import { WizardState } from '../../store/wizard.state';

@Component({
  selector: 'hmt-service-shipment-method-selector',
  templateUrl: './service-shipment-method-selector.component.html',
  styleUrl: './service-shipment-method-selector.component.scss',
})
export class ServiceShipmentMethodSelectorComponent extends FieldType implements OnInit, OnDestroy {
  store = inject(Store);
  selectedServiceCard$ = this.store.select(WizardState.getServiceCard);
  private destroy$ = new Subject<void>();

  initialLoadType: string = 'LCL';
  initialLoadDetails: ShipmentMethodDetail[] = [];
  disabled = false;
  radioHidden = false;

  ngOnInit(): void {
    if (this.field.form instanceof FormGroup) {
      this.field.form.addControl('shipmentMethod', new FormControl(null));
      this.addRooValidators();
    } else {
      console.error('Form is not an instance of FormGroup');
    }

    this.selectedServiceCard$
      .pipe(
        take(1),
        tap(serviceCard => {
          if (serviceCard) {
            const { serviceId } = serviceCard;
            let { loadDetails } = serviceCard;
            const firstLoadDetail = loadDetails[0] || {};
            const isAirFreight = serviceId === AIR_FREIGHT;
            const hasContainerType = Boolean(firstLoadDetail.containerType);
            const hasVolumeWeight = Boolean(firstLoadDetail.volumeWeight);

            if (isAirFreight) {
              this.initialLoadType = 'LCL';
              this.radioHidden = true;
              //TODO: remove. this is a temporary fix since the data is faulty. fix the backend bug. contains data of FCL even for air freight.
              if (hasContainerType) {
                loadDetails = [];
              }
            } else {
              this.initialLoadType = !hasContainerType ? 'LCL' : 'FCL';
            }

            // Update disabled state and load type for special cases
            if (!hasContainerType && !hasVolumeWeight) {
              this.disabled = isAirFreight;
            }

            // Set initial load details
            if (hasVolumeWeight && hasContainerType) {
              this.initialLoadDetails = [{ containerType: '', noOfLoads: null, volumeWeight: null }];
            } else if (hasVolumeWeight && !hasContainerType) {
              const combinedLoadDetails = loadDetails.reduce(
                (acc, detail) => ({
                  containerType: detail?.containerType ?? '',
                  noOfLoads: acc.noOfLoads + (detail?.noOfLoads ?? 0),
                  volumeWeight: acc.volumeWeight + (detail?.volumeWeight ?? 0),
                }),
                { containerType: '', noOfLoads: 0, volumeWeight: 0 }
              );

              this.initialLoadDetails = [combinedLoadDetails];
              this.onLoadDetailsChange({
                loadDetails: this.initialLoadDetails,
                shipmentMethod: this.initialLoadType,
              });
            } else if (hasContainerType && !hasVolumeWeight) {
              this.initialLoadDetails = loadDetails;
              this.onLoadDetailsChange({
                loadDetails: this.initialLoadDetails,
                shipmentMethod: this.initialLoadType,
              });
            }
          }
        })
      )
      .subscribe();
  }
  onLoadDetailsChange(shipmentData: { loadDetails: ShipmentMethodDetail[]; shipmentMethod: string }): void {
    const { loadDetails, shipmentMethod } = shipmentData || {};
    if (!loadDetails || !shipmentMethod) return;

    // const target = this.model || this.field.form.controls;
    const loadDetailsControl = this.field.form.controls['loadDetails'] as FormControl;
    const shipmentMethodControl = this.field.form.controls['shipmentMethod'] as FormControl;

    const isValid = this.validateShipmentData(shipmentMethod, loadDetails);

    if (isValid) {
      this.field.form.controls['loadDetails']?.patchValue?.(loadDetails);
      this.field.form.controls['shipmentMethod']?.patchValue?.(shipmentMethod);
      loadDetailsControl?.setErrors(null);
      shipmentMethodControl?.setErrors(null);
    } else {
      loadDetailsControl?.setErrors({ invalid: true });
      shipmentMethodControl?.setErrors({ invalid: true });
    }
  }

  private validateShipmentData(shipmentMethod: string, loadDetails: ShipmentMethodDetail[]): boolean {
    if (!this.getJobFile?.parentJobRefId) {
      return true;
    }

    const isValidFCL =
      shipmentMethod === 'FCL' &&
      loadDetails.length > 0 &&
      loadDetails.every(detail => detail.containerType && detail.noOfLoads > 0);

    const isValidLCL =
      shipmentMethod === 'LCL' &&
      loadDetails.some(detail => detail.volumeWeight > 0) &&
      loadDetails.some(detail => detail.noOfLoads > 0);

    return isValidFCL || isValidLCL;
  }

  get getJobFile(): JobFile {
    return this.store.selectSnapshot(WizardState.getJobFile);
  }

  addRooValidators(): void {
    const loadDetailsControl = this.field.form.controls['loadDetails'] as FormControl;
    // Make loadDetails control required
    if (loadDetailsControl) {
      if (this.getJobFile?.parentJobRefId) {
        loadDetailsControl.setValidators([Validators.required]);
      }
      loadDetailsControl.updateValueAndValidity();
      loadDetailsControl.markAsTouched();
      loadDetailsControl.markAsDirty();
    }

    // Make shipmentMethod control required
    const shipmentMethodControl = this.field.form.controls['shipmentMethod'] as FormControl;
    if (shipmentMethodControl) {
      if (this.getJobFile?.parentJobRefId) {
        shipmentMethodControl.setValidators([Validators.required]);
      }
      shipmentMethodControl.updateValueAndValidity();
      shipmentMethodControl.markAsTouched();
      shipmentMethodControl.markAsDirty();
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
