import { Injectable } from '@angular/core';
import {
  FIND_COUNTRIES_BY_NAMES_QUERY,
  FIND_COUNTRIES_QUERY,
  GENERATE_DRY_HMT_ACTIVITY_MUTATION,
} from '@shared/gql-shared-queries';
import { Apollo } from 'apollo-angular';
import { catchError, map, of, take } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class WizardService {
  constructor(private apollo: Apollo) {}

  generateDryHmtActivity(activityId: string, plannedServiceId: string, previousActivityId: string) {
    return this.apollo
      .mutate({
        mutation: GENERATE_DRY_HMT_ACTIVITY_MUTATION,
        variables: { generateDryActivityInput: { activityId, plannedServiceId, previousActivityId } },
      })
      .pipe(
        take(1),
        map(result => result.data),
        catchError(err => {
          console.error('Error generating dry HMT activity', err);
          return of(null);
        })
      );
  }

  getCountriesByShortCodes(countryCodes: string[]) {
    return this.apollo
      .query({
        query: FIND_COUNTRIES_QUERY,
        variables: { countryCodes },
      })
      .pipe(
        take(1),
        map(result => result.data['getCountriesByShortCodes'])
      );
  }

  getCountriesByNames(countryNames: string[]) {
    return this.apollo
      .query({
        query: FIND_COUNTRIES_BY_NAMES_QUERY,
        variables: { countryNames },
      })
      .pipe(
        take(1),
        map(result => result.data['getCountriesByNames'])
      );
  }
}
