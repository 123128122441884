<div class="document-upload">
  <div class="document-upload-header">
    <div>Documents</div>
  </div>
  <div class="document-upload-body">
    <span class="required-documents">Required Documents</span>
    <mat-accordion [togglePosition]="'before'">
      <mat-expansion-panel *ngFor="let entry of (serviceCard$ | async)?.orderShippingDocuments | keyvalue">
        <mat-expansion-panel-header class="header">
          <mat-panel-title class="title">{{ entry.key }}</mat-panel-title>
        </mat-expansion-panel-header>
        <div class="document-upload-body">
          <div *ngFor="let category of props['categories']">
            <hmt-document-upload-category
              [categoryName]="category"
              [files]="getDocumentsOfCategory(category, entry.value)"></hmt-document-upload-category>
          </div>
        </div>
      </mat-expansion-panel>
    </mat-accordion>
  </div>
</div>
