<div class="party-details-container">
  <div class="header">
    <div class="header-content">
      <div class="header-title">{{ componentType }}</div>
      <div class="header-sub-title">{{ party?.orgName }}</div>
    </div>
    <div>
      <img class="logo" alt="logo" *ngIf="(logo | async)?.url" [src]="(logo | async)?.url" />
    </div>
    <img
      class="logo"
      alt="logo"
      *ngIf="!(logo | async)?.url"
      src="assets/placeholders/image_placeholder.svg"
      alt="Default Logo" />
  </div>
  <div class="content">
    <div class="contact-details">
      <div class="contact-details-header sub-header">
        <span>Contact Details</span>
        <div class="horizontal-line"></div>
      </div>
      <div class="party-data">
        <div class="address">
          <div class="details-header content-header">Address</div>
          <div class="address content-style">
            {{ party?.registeredAddress?.addressLine || '--' }}
          </div>
        </div>
        <div class="contact-data">
          <div>
            <span class="details-header content-header">Phone : </span>
            <span class="address content-style">
              {{ party?.phone || '--' }}
            </span>
          </div>
          <div>
            <span class="details-header content-header">Fax : </span>
            <span class="address content-style">
              {{ party?.fax || '--' }}
            </span>
          </div>
          <div>
            <span class="details-header content-header">Email : </span>
            <span class="address content-style">
              {{ party?.email || '--' }}
            </span>
          </div>
        </div>
      </div>
    </div>
    <div class="principle-contact">
      <div class="principle-contact-header sub-header">
        <span>Principle Details</span>
        <div class="horizontal-line"></div>
      </div>
      <div class="principle-contact-content">
        <div>
          <span class="details-header content-header">Name : </span>
          <span class="address content-style">
            {{ party?.principalContact?.firstName || '--' }}
            {{ party?.principalContact?.lastName || '--' }}
          </span>
        </div>
        <div>
          <span class="details-header content-header">Mobile : </span>
          <span class="address content-style">
            {{ party?.principalContact?.mobile || '--' }}
          </span>
        </div>
      </div>
    </div>
  </div>
  <div class="footer">
    <div>
      <span class="details-header content-header">Company Reg # </span>
      <span class="address content-style">
        {{ party?.companyRegNum || '--' }}
      </span>
    </div>
    <div>
      <span class="details-header content-header">VAT # </span>
      <span class="address content-style">
        {{ party?.vatNum || '--' }}
      </span>
    </div>
    <div>
      <span class="details-header content-header">TIN # </span>
      <span class="address content-style">
        {{ party?.tinNum || '--' }}
      </span>
    </div>
  </div>
</div>
