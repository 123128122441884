<div class="ag-grid-container">
  <ag-grid-angular
    style="height: 100%"
    class="ag-theme-material"
    [gridOptions]="gridOptions"
    [rowData]="rowData"
    (rowValueChanged)="onRowValueChanged()"
    (gridReady)="onGridReady($event)"
    [editType]="editType"
    [columnDefs]="columnDefs">
  </ag-grid-angular>
</div>
