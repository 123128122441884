export enum TenderStatus {
  UNASSIGNED = 'Unassigned', // When the service is not assigned internally or contracted long term or tendered
  INTERNALLY_ASSIGNED = 'Internally Assigned', // When the service is assigned to an internal personnel
  CONTRACT_PENDING = 'Contract Pending', // When the service is contracted long term but the contracted party still hasn’t accepted
  CONTRACTED = 'Contracted', // When the service is contracted long term and the contracted party has accepted
  TENDER_PENDING = 'Tender Pending', // When the tender is not yet published but in the process of being created
  TENDER_IN_PROGRESS = 'Tender in Progress', // When the tender is published but not yet awarded
  TENDER_AWARDED = 'Tender Awarded', // When a tender is awarded for the specific service(s)
  PUBLISHED = 'PUBLISHED', //can't bid
  IN_PROGRESS = 'In Progress', //can bid
  EVALUATING = 'Evaluating',
  TERMINATED = 'Terminated',
  EXPIRED = 'Expired',
  AWARDED = 'Awarded',
  ALL_BIDS_REJECTED = 'All Bids Rejected', // When a tender is abandoned
  PARTIALLY_ABANDONED = 'PARTIALLY_ABANDONED',
  ABANDONED = 'Abandoned',
  COMPLETED = 'COMPLETED',
}
