import { Component, Input, OnInit, inject } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { SCOPES } from '@configs/scopes';
import { checkScopesReactive } from '@core/guards/auth.guard';
import { DialogHandlerService } from '@core/services/dialog-handler.service';
import { FieldWrapper } from '@ngx-formly/core';
import { Store } from '@ngxs/store';
import { ConfirmationDialogComponent } from '@shared/components/confirmation-dialog/confirmation-dialog.component';
import { EntityMap } from '@shared/types';
import { Subject, first, takeUntil, tap } from 'rxjs';
import { JsonSchemaService } from '../../../../json-schema-forms/services/json-schema.service';
import { FetchInjectedScreen } from '../../../../json-schema-forms/store/workflow.actions';
import { WorkflowState } from '../../../../json-schema-forms/store/workflow.state';
import { Shipment } from '../../models/shipment.model';
import { DeselectShipment, RemoveShipmentWithReference, SelectShipment } from '../../store/wizard.actions';
import { WizardState } from '../../store/wizard.state';

@Component({
  selector: 'hmt-shipment-summary',
  templateUrl: './shipment-summary.component.html',
  styleUrl: './shipment-summary.component.scss',
})
export class ShipmentSummaryComponent extends FieldWrapper implements OnInit {
  store$ = inject(Store);
  injectableScreen$ = this.store$.select(WorkflowState.getInjectedScreen);
  shipments$ = this.store$.select(WizardState.getShipments);
  selectedShipmentsForTender$ = this.store$.select(WizardState.getSelectedShipmentsForTender);
  selectedServicesForTender$ = this.store$.select(WizardState.getSelectedServicesForTender);
  selectedOptions$ = this.store$.select(WizardState.getActiveOptionIdWithShipmentId);
  jobFile$ = this.store$.select(WizardState.getJobFile);
  selectedServicesByShipmentId$ = this.store$.select(WizardState.getSelectedServicesByShipmentId);
  currentStep$ = this.store$.select(WorkflowState.getCurrentStep);
  selectedShipments: { [key: string]: boolean } = {};
  wizardGeneralScopes$ = checkScopesReactive({ oneOf: [SCOPES.JOB_FILES.CREATE] });

  @Input() isTenderWizard = false;

  private $destroy: Subject<void> = new Subject<void>();

  private readonly dialogHandlerService = inject(DialogHandlerService);
  private readonly jsonSchemaService = inject(JsonSchemaService);
  private matDialog = inject(MatDialog);

  ngOnInit(): void {
    this.shipments$
      .pipe(
        takeUntil(this.$destroy),
        tap((shipments: Shipment[]) => {
          // if there is only one shipment, select it
          const parentJobRefId = this.store$.selectSnapshot(WizardState.getJobFile).parentJobRefId;
          if (shipments.length === 1 && !this.selectedShipments[shipments[0]._id] && parentJobRefId) {
            this.selectedShipments[shipments[0]._id] = true;
            this.store$.dispatch(new SelectShipment(shipments[0]._id));
          }
        })
      )
      .subscribe();
    this.selectedServicesByShipmentId$
      .pipe(
        takeUntil(this.$destroy),
        tap((shipmentsSelected: EntityMap<string, string[]>) => {
          for (const shipmentId in shipmentsSelected) {
            if (!this.selectedShipments[shipmentId]) {
              this.selectedShipments[shipmentId] = true;
            }
          }
        })
      )
      .subscribe();
  }

  viewOrderData() {
    this.store$.dispatch(new FetchInjectedScreen(this.props['orders'][0]?.screenId));

    this.injectableScreen$
      .pipe(
        first(screen => !!screen),
        tap(screen => {
          if (screen) {
            this.dialogHandlerService.openSchemaDialog(
              this.jsonSchemaService.generateFormWithSchema(screen.schema, screen.model),
              {
                backdropClass: 'backdropBackground',
                width: '800px',
                height: '100%',
                panelClass: 'custom-dialog-container',
              }
            );
          }
        })
      )
      .subscribe();
  }

  onShipmentSelectionChange(shipment: Shipment, isSelected: boolean): void {
    if (isSelected) {
      this.store$.dispatch(new SelectShipment(shipment._id));
      this.selectedShipments[shipment._id] = true;
    } else {
      this.store$.dispatch(new DeselectShipment(shipment._id));
      this.selectedShipments[shipment._id] = false;
    }
  }

  isShipmentSelected(shipmentId: string): boolean {
    return this.selectedShipments[shipmentId] || false;
  }

  deleteShipment(shipment: Shipment) {
    this.matDialog.open(ConfirmationDialogComponent, {
      data: {
        success: false,
        title: 'Delete Shipment',
        message: 'Are you sure you want to delete this shipment?',
        confirmButtonText: 'Yes',
        showCancel: true,
        onConfirmCallback: () => this.store$.dispatch(new RemoveShipmentWithReference(shipment._id)),
      },
    });
  }
}
