<div class="activity-container">
  <div *ngIf="serviceCard$ | async as serviceCard">
    @for (item of activities$ | async; track item._id; let i = $index) {
      <hmt-activity
        [activity]="item"
        [plannedServiceId]="serviceCard?.plannedServiceId"
        [workflowDefId]="serviceCard?.serviceId"
        [previousActivityId]="item._id"></hmt-activity>
    }
  </div>
</div>
