<div class="unsmartened-orders-container">
  <div class="header">
    <span class="header-title">Orders</span>
    <mat-form-field appearance="outline">
      <!--TODO: enable when backend is ready-->
      <input matInput placeholder="Search" disabled="true" />
    </mat-form-field>
  </div>
  <div class="content">
    @for (order of orders$ | async; track order._id) {
      <hmt-order-card [order]="order" [viewOnly]="true" [hideCheckBox]="true"></hmt-order-card>
    }
  </div>
</div>
