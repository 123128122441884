<div class="service-view-tab-container" [ngClass]="getStylesClasses('container')">
  <form
    [formGroup]="form"
    [ngClass]="getStylesClasses('form')"
    class="formly-form"
    (ngSubmit)="submit()"
    class="dynamic-content-container formly-form">
    <formly-form class="formly-form" [form]="form" [model]="model" [fields]="fields" [options]="options"> </formly-form>
  </form>
</div>
