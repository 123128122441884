import { Component, OnInit } from '@angular/core';
import { FieldType } from '@ngx-formly/core';
import { Select } from '@ngxs/store';
import { ColDef } from 'ag-grid-community';
import { filter, Observable, take, tap } from 'rxjs';
import { PackingList } from '../../models/packing-list.model';
import { ServiceCard } from '../../models/service-card/service-card.model';
import { WizardState } from '../../store/wizard.state';

@Component({
  selector: 'hmt-shipment-packing-list-table',
  templateUrl: './shipment-packing-list-table.component.html',
  styleUrl: './shipment-packing-list-table.component.scss',
})
export class ShipmentPackingListTableComponent extends FieldType implements OnInit {
  transformedPackageList: PackingList<number, string>[] = [];
  columns: ColDef[] = [];
  @Select(WizardState.getServiceCard) serviceCard$: Observable<ServiceCard>;

  columnNames: { header: string; field: string; type?: string; cellEditor?: string; cellEditorParams?: any }[] = [
    { header: 'ItemRef Id', field: 'itemRefId', type: 'text', cellEditor: 'agTextCellEditor' },
    { header: 'Commodity', field: 'commodity', type: 'text', cellEditor: 'agTextCellEditor' },
    { header: 'HS Codes', field: 'hsCodes', type: 'text', cellEditor: 'agTextCellEditor' },
    { header: 'Description Of Cargo', field: 'descriptionOfCargo', type: 'text', cellEditor: 'agTextCellEditor' },
    { header: 'Packaging Type', field: 'packagingType', type: 'text', cellEditor: 'agTextCellEditor' },
    { header: 'No of Units', field: 'noOfUnits', type: 'number', cellEditor: 'agNumberCellEditor' },
    { header: 'Dimension(H)', field: 'dimensionH', type: 'number', cellEditor: 'agNumberCellEditor' },
    { header: 'Dimension(W)', field: 'dimensionW', type: 'number', cellEditor: 'agNumberCellEditor' },
    { header: 'Dimension(L)', field: 'dimensionL', type: 'number', cellEditor: 'agNumberCellEditor' },
    { header: 'Weight Per Unit', field: 'weightPerUnit', type: 'number', cellEditor: 'agNumberCellEditor' },
    { header: 'Gross Weight', field: 'grossWeight', type: 'number', cellEditor: 'agNumberCellEditor' },
    { header: 'Net Weight', field: 'netWeight', type: 'number', cellEditor: 'agNumberCellEditor' },
    { header: 'Chargeable Weight', field: 'chargeableWeight', type: 'number', cellEditor: 'agNumberCellEditor' },
    { header: 'Volume', field: 'volume', type: 'number', cellEditor: 'agNumberCellEditor' },
    {
      header: 'Dangerous Cargo',
      field: 'dangerousCargo',
      cellEditor: 'agSelectCellEditor',
      cellEditorParams: { values: ['Yes', 'No'] },
    },
    {
      header: 'Special Cargo',
      field: 'specialRequirements',
      cellEditor: 'agSelectCellEditor',
      cellEditorParams: { values: ['Yes', 'No'] },
    },
  ];

  ngOnInit(): void {
    this.constructPackingList();
    this.generateColDefs();
  }

  generateColDefs(): void {
    this.columnNames.forEach(column => {
      this.columns.push({
        headerName: column.header,
        field: column.field,
        editable: true,
        cellDataType: column.type,
        cellEditor: column.cellEditor,
        cellEditorParams: column.cellEditorParams,
        valueSetter: params => {
          if (params.newValue) {
            params.data[params.colDef.field] = params.newValue;
            return true;
          }
          return false;
        },
        cellClassRules: {
          'cell-error': params => !params.value, // Add error class if value is empty
        },
      });
    });
  }

  constructPackingList() {
    this.serviceCard$
      .pipe(
        filter(serviceCard => !!serviceCard),
        take(1),
        tap(serviceCard => {
          if (serviceCard && serviceCard?.cargoDetails?.packingList) {
            this.transformedPackageList = serviceCard?.cargoDetails?.packingList?.map(packingList => {
              return {
                orderRefNumber: packingList?.orderRefNumber,
                itemRefId: packingList?.itemRefId,
                commodity: packingList?.commodity,
                hsCodes: packingList?.hsCodes,
                descriptionOfCargo: packingList?.descriptionOfCargo,
                packagingType: packingList?.packagingType,
                noOfUnits: packingList?.noOfUnits || 0,
                dimensionH: packingList?.dimensionH || 0,
                dimensionW: packingList?.dimensionW || 0,
                dimensionL: packingList?.dimensionL || 0,
                weightPerUnit: packingList?.weightPerUnit || 0,
                grossWeight: packingList?.grossWeight || 0,
                netWeight: packingList?.netWeight || 0,
                chargeableWeight: packingList?.chargeableWeight || 0,
                volume: packingList?.volume || 0,
                dangerousCargo: this.booleanToYesNo(packingList?.dangerousCargo),
                specialRequirements: this.booleanToYesNo(packingList?.specialRequirements),
              };
            });
          }
        })
      )
      .subscribe();
  }

  booleanToYesNo(value: boolean): string {
    return value ? 'Yes' : 'No';
  }
}
