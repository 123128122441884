<div class="shipment-reference-container">
  <div class="content">
    <mat-accordion multi [togglePosition]="'before'">
      <mat-expansion-panel
        *ngFor="let shipment of isTenderWizard ? (selectedShipmentsForTender$ | async) : (shipments$ | async)"
        [expanded]="true">
        <mat-expansion-panel-header class="header">
          <mat-checkbox
            class="checkbox"
            *ngIf="!!(jobFile$ | async)?.parentJobRefId && !isTenderWizard"
            [checked]="isShipmentSelected(shipment._id)"
            (change)="onShipmentSelectionChange(shipment, $event.checked)"
            (click)="$event.stopPropagation()"></mat-checkbox>
          <mat-panel-title class="title">Shipment Reference No: {{ shipment?.shipmentRefNumber }}</mat-panel-title>
          <ng-container *ngIf="!(jobFile$ | async)?.parentJobRefId && !isTenderWizard">
            <button
              class="delete-btn"
              mat-mini-fab
              aria-label="Delete"
              (click)="deleteShipment(shipment)"
              [ngClass]="{
                'disabled-button': (wizardGeneralScopes$ | async) ? false : true
              }">
              <mat-icon>delete</mat-icon>
            </button>
          </ng-container>
          <button
            class="disabled-button btn-purple-outline"
            [disabled]="true"
            *ngIf="isTenderWizard"
            mat-stroked-button>
            Set Information Visibility
          </button>
        </mat-expansion-panel-header>
        <ng-container *ngIf="shipment?.serviceOptions?.length === 1 || isTenderWizard; else multipleOptions">
          <ng-container
            *ngTemplateOutlet="
              optionContent;
              context: {
                option: !isTenderWizard ? shipment?.serviceOptions[0] : (selectedOptions$ | async)[shipment._id]
              }
            "></ng-container>
        </ng-container>

        <ng-template #multipleOptions>
          <ng-container *ngFor="let option of shipment?.serviceOptions; let i = index">
            <div class="multiple-options-container">
              <span class="option-title">Option {{ i + 1 }}</span>
              <ng-container *ngTemplateOutlet="optionContent; context: { option: option }"></ng-container>
            </div>
          </ng-container>
        </ng-template>
        <ng-template #optionContent let-option="option">
          <div class="content">
            <div class="row-1 row">
              <div class="col col-1">
                <div>Shipment Methods</div>
                <div class="col-content">
                  <div class="shipment-type-container">
                    <div class="shipment-type-col">
                      <div class="title">Mode</div>
                      <div class="content">{{ option?.shipmentMode | statusToLower: '/' }}</div>
                    </div>
                    <!--TODO: enable id needed-->
                    <!-- <div class="shipment-type-col">
                      <div class="title">Method</div>
                      <div content></div>
                    </div> -->
                  </div>
                </div>
              </div>
              <div class="col col-2">
                <div>Services Requested</div>
                <div class="col-content">
                  <ng-container *ngIf="!isTenderWizard">
                    <div *ngFor="let service of option.services">
                      <div class="service">
                        {{ service?.serviceName }}
                      </div>
                    </div>
                  </ng-container>
                  <ng-container *ngIf="isTenderWizard">
                    <div *ngFor="let service of selectedServicesForTender$ | async">
                      <div class="service">
                        {{ service?.serviceName }}
                      </div>
                    </div>
                  </ng-container>
                </div>
              </div>
            </div>
          </div>
        </ng-template>
        <div class="row-2 row">
          <div class="row search-row">
            <span>Orders</span>
            <mat-form-field appearance="outline">
              <mat-label>Search</mat-label>
              <!--TODO: enable when backend is ready-->
              <input matInput placeholder="Search" disabled="true" />
            </mat-form-field>
          </div>
          <div class="orders-container">
            <hmt-order-card
              [hideCheckBox]="true"
              [viewOnly]="true"
              *ngFor="let order of shipment?.orders"
              [order]="order"></hmt-order-card>
          </div>
        </div>
      </mat-expansion-panel>
    </mat-accordion>
  </div>
</div>
