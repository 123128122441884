<div class="route-details" *ngIf="serviceCard$ | async as serviceCard">
  <div class="header">Route Details</div>
  <div class="row detail-item">
    <div class="inner-row">
      <div class="label">Transit Time</div>
      <div class="value">{{ serviceCard?.routingDetails?.transitTime }}</div>
    </div>
    <div class="inner-row" *ngIf="serviceCard?.serviceId === 'OCEAN-FREIGHT'">
      <div class="label">Shipping Line</div>
      <div class="value">{{ serviceCard?.routingDetails?.shippingLine }}</div>
    </div>
    <div class="inner-row" *ngIf="serviceCard?.serviceId === 'AIR-FREIGHT'">
      <div class="label">Airline</div>
      <div class="value">{{ serviceCard?.routingDetails?.airline }}</div>
    </div>
  </div>

  <div class="row detail-item">
    <div class="inner-row" *ngIf="serviceCard?.serviceId === 'OCEAN-FREIGHT'">
      <div class="label">Vessel</div>
      <div class="value">{{ serviceCard?.routingDetails?.vessel }}</div>
    </div>
    <div class="inner-row" *ngIf="serviceCard?.serviceId === 'AIR-FREIGHT'">
      <div class="label">Flight</div>
      <div class="value">{{ serviceCard?.routingDetails?.flight }}</div>
    </div>
    <div class="inner-row">
      <div class="label">Voyage</div>
      <div class="value">{{ serviceCard?.routingDetails?.voyage }}</div>
    </div>
  </div>

  <div class="row detail-item">
    <div class="inner-row">
      <div class="label">Routing</div>
      <div class="value">{{ serviceCard?.routingDetails?.transitType === 'DIRECT' ? 'Direct' : 'Transit' }}</div>
    </div>
    <div class="inner-row"></div>
  </div>

  <div class="row detail-item">
    <div class="inner-row">
      <div class="label">Country of Origin</div>
      <div class="value">{{ getCountryName(serviceCard?.routingDetails?.countryOfOrigin) }}</div>
    </div>
    <div class="inner-row"></div>
  </div>

  <div class="row detail-item">
    <div class="inner-row" *ngIf="serviceCard?.serviceId === 'OCEAN-FREIGHT'">
      <div class="label">Loading Port At Origin</div>
      <div class="value">{{ getLocationName(serviceCard?.routingDetails?.loadingLocation) }}</div>
    </div>
    <div class="inner-row" *ngIf="serviceCard?.serviceId === 'AIR-FREIGHT'">
      <div class="label">Loading Airport At Origin</div>
      <div class="value">{{ getLocationName(serviceCard?.routingDetails?.loadingLocation) }}</div>
    </div>
    <div class="col">
      <div class="inner-row">
        <div class="label">ETA</div>
        <div class="value">{{ serviceCard?.routingDetails?.loadingLocationETA | date: "d/MMMM/yyyy HHmm'h'" }}</div>
      </div>
      <div class="inner-row">
        <div class="label">ETD</div>
        <div class="value">{{ serviceCard?.routingDetails?.loadingLocationETD | date: "d/MMMM/yyyy HHmm'h'" }}</div>
      </div>
    </div>
  </div>

  <div class="row detail-item">
    <div class="inner-row">
      <div class="label">Loading Terminal At Origin</div>
      <div class="value">{{ getLocationName(serviceCard?.routingDetails?.loadingTerminal) }}</div>
    </div>
    <div class="col">
      <div class="inner-row">
        <div class="label">ETA</div>
        <div class="value">{{ serviceCard?.routingDetails?.loadingTerminalETA | date: "d/MMMM/yyyy HHmm'h'" }}</div>
      </div>
      <div class="inner-row">
        <div class="label">ETD</div>
        <div class="value">{{ serviceCard?.routingDetails?.loadingTerminalETD | date: "d/MMMM/yyyy HHmm'h'" }}</div>
      </div>
    </div>
  </div>

  <div class="row detail-item">
    <div class="inner-row">
      <div class="label">Unloading Country At Destination</div>
      <div class="value">{{ getCountryName(serviceCard?.routingDetails?.countryOfDestination) }}</div>
    </div>
    <div class="inner-row"></div>
  </div>

  <div class="row detail-item">
    <div class="inner-row" *ngIf="serviceCard?.serviceId === 'OCEAN-FREIGHT'">
      <div class="label">Unloading Port At Destination</div>
      <div class="value">{{ getLocationName(serviceCard?.routingDetails?.unloadingLocation) }}</div>
    </div>
    <div class="inner-row" *ngIf="serviceCard?.serviceId === 'AIR-FREIGHT'">
      <div class="label">Unloading Airport At Destination</div>
      <div class="value">{{ getLocationName(serviceCard?.routingDetails?.unloadingLocation) }}</div>
    </div>
    <div class="col">
      <div class="inner-row">
        <div class="label">ETA</div>
        <div class="value">{{ serviceCard?.routingDetails?.unloadingLocationETA | date: "d/MMMM/yyyy HHmm'h'" }}</div>
      </div>
      <div class="inner-row">
        <div class="label">ETD</div>
        <div class="value">{{ serviceCard?.routingDetails?.unloadingLocationETD | date: "d/MMMM/yyyy HHmm'h'" }}</div>
      </div>
    </div>
  </div>

  <div class="row detail-item">
    <div class="inner-row">
      <div class="label">Unloading Terminal at Destination</div>
      <div class="value">{{ getLocationName(serviceCard?.routingDetails?.unloadingTerminal) }}</div>
    </div>
    <div class="col">
      <div class="inner-row">
        <div class="label">ETA</div>
        <div class="value">{{ serviceCard?.routingDetails?.unloadingTerminalETA | date: "d/MMMM/yyyy HHmm'h'" }}</div>
      </div>
      <div class="inner-row">
        <div class="label">ETD</div>
        <div class="value">{{ serviceCard?.routingDetails?.unloadingTerminalETD | date: "d/MMMM/yyyy HHmm'h'" }}</div>
      </div>
    </div>
  </div>

  <div class="activities">
    <div class="act-header">Activities</div>
    <div class="content">
      <div class="line-item" *ngFor="let activity of activities">
        <div class="icon">
          <mat-icon>circle_check</mat-icon>
        </div>
        <div class="wo">{{ activity?.activityName }}</div>
        <div class="location">
          <span class="placeholder">Location : &nbsp;</span> {{ getLocationName(activity?.locationId) }}
        </div>
      </div>
    </div>
  </div>
</div>
