import { Component } from '@angular/core';
import { FieldWrapper } from '@ngx-formly/core';
import { Select } from '@ngxs/store';
import { Observable } from 'rxjs';
import { Activity } from '../../models/activity.model';
import { ServiceCard } from '../../models/service-card/service-card.model';
import { WizardState } from '../../store/wizard.state';

@Component({
  selector: 'hmt-activity-container',
  templateUrl: './activity-container.component.html',
  styleUrl: './activity-container.component.scss',
})
export class ActivityContainerComponent extends FieldWrapper<any> {
  @Select(WizardState.getServiceCard) serviceCard$: Observable<ServiceCard>;
  @Select(WizardState.getServiceCardActivities) activities$: Observable<Activity[]>;
}
