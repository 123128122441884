<div class="container">
  @if (serviceCard$ | async; as serviceCard) {
    <div class="col-1">
      <mat-form-field appearance="outline">
        <mat-label>Preferred Shipment Mode</mat-label>
        <mat-select [(ngModel)]="serviceCard.shipmentMode" [disabled]="true">
          @for (shipmentMode of shipmentModes; track shipmentMode.key) {
            <mat-option [value]="shipmentMode.key">{{ shipmentMode.value }}</mat-option>
          }
        </mat-select>
      </mat-form-field>
    </div>
    <div class="col-2">
      <span>Transhipment</span>
      <mat-radio-group [value]="serviceCard.transhipment" [disabled]="true">
        <mat-radio-button [value]="true">Allowed</mat-radio-button>
        <mat-radio-button [value]="false">Not Allowed</mat-radio-button>
      </mat-radio-group>
    </div>
  }
</div>
