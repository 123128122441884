<div class="orders-table-container">
  <hmt-dynamic-table
    style="padding-bottom: 10px"
    [sortable]="false"
    [editable]="true"
    [columnDefs]="columns"
    [data]="transformedPackageList"
    (savedData)="patchDataToModel($event)"></hmt-dynamic-table>
  <mat-error *ngIf="field.form.controls['packingList']?.errors?.required">{{
    field.validation?.messages['required']
  }}</mat-error>
</div>
