<div class="header">
  <div class="close-button" (click)="close()">
    <mat-icon>close</mat-icon>
  </div>
  <div class="title">View Orders</div>
</div>
<div class="content">
  <div class="row-1">
    <span>Orders</span>
    <mat-form-field appearance="outline">
      <input matInput formControlName="searchControl" placeholder="Search" />
    </mat-form-field>
  </div>
  <div class="row-2">
    <hmt-order-card *ngFor="let order of data?.orders" [order]="order" [viewOnly]="true"></hmt-order-card>
  </div>
</div>
