import { Component, EventEmitter, Inject, Output } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'hmt-shipment-creation-selector',
  templateUrl: './shipment-creation-selector.component.html',
  styleUrl: './shipment-creation-selector.component.scss',
})
export class ShipmentCreationSelectorComponent {
  @Output() createShipment = new EventEmitter();
  @Output() continueToAssignServices = new EventEmitter();

  constructor(
    private dialogRef: MatDialogRef<ShipmentCreationSelectorComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      parentJobRefId: string;
    }
  ) {}

  close() {
    this.dialogRef.close();
  }

  createShipmentAction(event: string) {
    this.createShipment.emit(event);
    this.dialogRef.close();
  }

  continueToAssignServicesAction(event: string) {
    this.continueToAssignServices.emit({ shipment_creation: event });
    this.dialogRef.close();
  }
}
