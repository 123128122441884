import { ChangeDetectorRef, Component } from '@angular/core';
import { FieldType } from '@ngx-formly/core';
import { Select } from '@ngxs/store';
import { Observable } from 'rxjs';
import { ServiceCard } from '../../models/service-card/service-card.model';
import { ShippingDocument } from '../../models/shipping-document.model';
import { UploadedFileType } from '../../models/uploaded-file.type';
import { WizardState } from '../../store/wizard.state';

@Component({
  selector: 'hmt-shipment-document-wizard',
  templateUrl: './shipment-document-wizard.component.html',
  styleUrl: './shipment-document-wizard.component.scss',
})
export class ShipmentDocumentWizardComponent extends FieldType {
  constructor(private cdr: ChangeDetectorRef) {
    super();
  }

  @Select(WizardState.getServiceCard) serviceCard$: Observable<ServiceCard>;

  getDocumentsOfCategory(category: string, documents: ShippingDocument[]): UploadedFileType[] {
    return documents
      .filter(document => document.category === category)
      .map(document => document.documentDetails)
      .flat();
  }
}
