import { Component, EventEmitter, inject, OnInit, Output } from '@angular/core';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { GraphqlClientService } from '@core/services/graphql-client.service';
import {
  FIND_WORKFLOW_DEFINITION_BY_WORKFLOW_DEF_ID_QUERY,
  GET_LOCATION_BY_ID_QUERY,
} from '@shared/gql-shared-queries';
import { Location } from '@shared/models/location.model';
import { filter, take, tap } from 'rxjs';
import { Activity } from '../../models/activity.model';
import { WizardService } from '../../services/wizard.service';

@Component({
  selector: 'hmt-add-activity',
  templateUrl: './add-activity.component.html',
  styleUrl: './add-activity.component.scss',
})
export class AddActivityComponent implements OnInit {
  @Output() activityUpdated = new EventEmitter<Activity>();
  @Output() activityAdded = new EventEmitter<Activity>();

  private fb = inject(FormBuilder);
  readonly dialogRef = inject(MatDialogRef<AddActivityComponent>);
  public data: {
    activity: Activity;
    editMode: boolean;
    previousActivityId: string;
    workflowDefId: string;
  } = inject(MAT_DIALOG_DATA);
  private wizardService = inject(WizardService);
  private route = inject(ActivatedRoute);
  private graphqlClient = inject(GraphqlClientService);

  activityForm: FormGroup;
  activity: Activity;
  additionalServices: { id: string; name: string }[] = [];
  fromLocation: Location;
  toLocation: Location;

  ngOnInit() {
    this.activity = this.data?.activity;
    this.fetchRequiredData();
    this.initForm();
  }

  fetchRequiredData() {
    this.graphqlClient
      .query<
        { findWorkflowDefinitionByWorkflowDefId: { additionalServicesInfo: { id: string; name: string }[] } },
        { workflowDefId: string }
      >(FIND_WORKFLOW_DEFINITION_BY_WORKFLOW_DEF_ID_QUERY, { workflowDefId: this.data.workflowDefId })
      .pipe(
        tap(result => {
          this.additionalServices = result.findWorkflowDefinitionByWorkflowDefId.additionalServicesInfo;
          this.addDataToForm();
        })
      )
      .subscribe();

    if (this.data?.editMode) {
      this.graphqlClient
        .query<{ findLocationById: Location }, { locationId: string }>(GET_LOCATION_BY_ID_QUERY, {
          locationId: this.data.activity?.locationId,
        })
        .pipe(
          filter(result => !!result),
          take(1),
          tap(result => {
            this.fromLocation = result.findLocationById;
            this.addDataToForm();
          })
        )
        .subscribe();

      this.graphqlClient
        .query<{ findLocationById: Location }, { locationId: string }>(GET_LOCATION_BY_ID_QUERY, {
          locationId: this.data.activity?.secondaryLocationId,
        })
        .pipe(
          filter(result => !!result),
          take(1),
          tap(result => {
            this.toLocation = result.findLocationById;
            this.addDataToForm();
          })
        )
        .subscribe();
    }
  }

  initForm() {
    this.activityForm = this.fb.group({
      activityType: [{ value: '', disabled: this.data.editMode }],
      locationType: [{ value: '', disabled: this.data.editMode }],
      fromLocation: [{ value: '', disabled: this.data.editMode }],
      toLocation: [{ value: '', disabled: this.data.editMode }],
      plannedStartTime: [''],
      actualStartTime: [''],
      plannedEndTime: [''],
      actualEndTime: [''],
      workOrders: this.fb.array([]),
    });

    if (this.data.activity) {
      this.activity = this.data.activity;
      this.addDataToForm();
      this.initWorkOrders();
    }
  }

  initWorkOrders() {
    const workOrdersFormArray = this.activityForm.get('workOrders') as FormArray;
    workOrdersFormArray.clear();
    this.activity.workOrders.forEach(workOrder => {
      workOrdersFormArray.push(
        this.fb.group({
          workOrderId: [workOrder.workOrderId],
          workOrderName: [workOrder.workOrderName],
          required: [workOrder.required],
          updateRequired: [workOrder.updateRequired],
          durationInMinutes: [workOrder.durationInMinutes],
        })
      );
    });
  }

  minutesToHoursAndMinutes(minutes: number): { hours: number; minutes: number } {
    const hours = Math.floor(minutes / 60);
    const mins = minutes % 60;
    return { hours, minutes: mins };
  }

  updateDuration(index: number, value: number, type: 'hours' | 'minutes') {
    const workOrdersArray = this.activityForm.get('workOrders') as FormArray;
    const workOrder = workOrdersArray.at(index);
    const currentDuration = workOrder.get('durationInMinutes').value;

    let newDuration: number;
    if (type === 'hours') {
      newDuration = value * 60 + (currentDuration % 60);
    } else {
      newDuration = Math.floor(currentDuration / 60) * 60 + value;
    }

    workOrder.patchValue({ durationInMinutes: newDuration });
  }

  locationDisplayFn(location: Location): string {
    return location ? location.locationName : '';
  }

  activityDisplayFn(service: { id: string; name: string }): string {
    return service ? service.name : '';
  }

  selectService(service: { id: string; name: string }) {
    this.wizardService
      .generateDryHmtActivity(
        service.id,
        this.route.snapshot.queryParamMap.get('serviceId'),
        this.data.previousActivityId
      )
      .pipe(
        tap(result => {
          this.activity = result['generateDryHmtActivity'];
          this.addDataToForm();
          this.initWorkOrders();
        })
      )
      .subscribe();
  }

  addDataToForm() {
    this.activityForm.patchValue({
      activityType: this.additionalServices.find(service => service.id === this.activity.activityId),
      locationType: this.activity.locationRelationshipType,
      fromLocation: this.fromLocation?.locationName,
      toLocation: this.toLocation?.locationName,
      plannedStartTime: this.activity.plannedStartDateTime,
      plannedEndTime: this.activity.plannedEndDateTime,
    });
  }

  onSubmit() {
    if (this.activityForm.valid) {
      const activity: Activity = {
        activityId: this.activity.activityId,
        activityName: this.activity.activityName,
        activityHeader: this.activity.activityHeader,
        serviceName: this.activity.serviceName,
        estimatedDuration: this.activity.estimatedDuration,
        orgId: this.activity.orgId,
        jobRefId: this.activity.jobRefId,
        shipmentId: this.activity.shipmentId,
        serviceOptionId: this.activity.serviceOptionId,
        locationType: this.activityForm.get('locationType').value,
        locationId: this.activityForm.get('fromLocation').value?._id,
        secondaryLocationId: this.activityForm.get('toLocation').value?._id,
        secondaryLocationRelationshipType: this.activityForm.get('locationType').value,
        actualStartDateTime: this.activityForm.get('actualStartTime').value,
        plannedEndDateTime: this.activityForm.get('plannedEndTime').value,
        plannedStartDateTime: this.activityForm.get('plannedStartTime').value,
        actualEndDateTime: this.activityForm.get('actualEndTime').value,
        workOrders: this.activityForm.get('workOrders').value.map(wo => ({
          workOrderId: wo.workOrderId,
          workOrderName: wo.workOrderName,
          required: wo.required,
          updateRequired: wo.updateRequired,
          durationInMinutes: wo.durationInMinutes,
        })),
      };
      if (this.activity?._id) {
        activity._id = this.activity._id;
      }

      if (this.data?.activity) {
        this.activityUpdated.emit(activity);
      } else {
        this.activityAdded.emit(activity);
      }

      // You can now use this activity object for further processing,
      // such as sending it to a service or dispatching an action
      this.dialogRef.close(this.activityForm.value);
    }
  }
}
