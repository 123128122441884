<div class="shipments-container">
  <div class="shipments-header">
    <div class="row-1">
      <span class="title">Shipments</span>
      <div class="button-group">
        <button
          mat-raised-button
          color="primary"
          (click)="createShipment()"
          [ngClass]="{
            'disabled-button': (wizardGeneralScopes$ | async) ? false : true
          }">
          Create Shipment
        </button>
      </div>
    </div>
    <div class="row-2" *ngIf="(shipments$ | async)?.length > 1">
      <!-- TODO: Add search functionality  when backend is ready -->
      <mat-form-field appearance="outline">
        <mat-icon matPrefix>search</mat-icon>
        <input matInput disabled="true" placeholder="Search" />
      </mat-form-field>
    </div>
  </div>
  <div class="shipments-body">
    <div class="empty-shipments-card" *ngIf="!(shipments$ | async)?.length">
      <div class="header">No shipments created at the moment</div>
      <div class="message">Create shipments by including one or more orders.</div>
      <div class="button-group">
        <button
          mat-raised-button
          color="primary"
          (click)="createShipment()"
          [ngClass]="{
            'disabled-button': (wizardGeneralScopes$ | async) ? false : true
          }">
          Create Shipment
        </button>
      </div>
    </div>
    <div class="created-shipments">
      <div *ngFor="let item of shipments$ | async; let isFirst = first">
        <hmt-shipment-card
          [shipment]="item"
          [isFirstCard]="isFirst"
          [injectedScreen$]="injectedScreen$"
          [parentJobRefId]="parentJobRefId"
          [shipmentMethods]="shipmentMethods"
          [assignedServices]="assignedServices$"
          [shipmentServiceOptions]="shipmentServiceOptions$"
          [defaultServiceConfigs]="getDefaultServiceConfigs$ | async"
          (selectedService)="setSelectedService($event)"
          [categorizedOrders]="categorizedOrders$"></hmt-shipment-card>
      </div>
    </div>
  </div>
</div>
