<mat-sidenav-container class="sidenav-container">
  <mat-sidenav class="side-nav" #drawer mode="over" position="end" opened>
    <mat-toolbar>
      <button mat-icon-button mat-dialog-close>
        <mat-icon>close</mat-icon>
      </button>
      <mat-divider vertical="true"></mat-divider>
      <span class="toolbar-title">Attach to a Shipment</span>
    </mat-toolbar>
    <mat-divider></mat-divider>
    <div class="search-container">
      <mat-form-field appearance="outline" class="search-field">
        <mat-icon matPrefix>search</mat-icon>
        <input matInput [formControl]="searchControl" placeholder="Search" />
      </mat-form-field>
    </div>
    <div class="orders-list">
      <mat-checkbox
        [disabled]="data?.shipmentOrders?.length ? false : firstSelectedOrder?.selected ? false : true"
        (change)="selectAll($event.checked)"
        >Orders</mat-checkbox
      >
      <div class="orders-container">
        <div class="order" *ngFor="let order of orderPageable?.items">
          <hmt-order-card
            [order]="order"
            [viewOnly]="true"
            (selectOrderAction)="checkedOrder($event.checked, order)"></hmt-order-card>
        </div>
      </div>
      <mat-paginator
        [length]="orderPageable?.pageInfo?.total"
        [pageSize]="orderPageable?.pageInfo?.pageSize"
        (page)="handlePageEvent($event)"
        [pageSizeOptions]="[5, 10, 25, 100]"
        aria-label="Select page">
      </mat-paginator>
    </div>
  </mat-sidenav>
</mat-sidenav-container>
<div class="footer row">
  <button mat-raised-button (click)="cancel()">Cancel</button
  ><button mat-raised-button (click)="saveSelectedOrders()" color="primary">Update</button>
</div>
