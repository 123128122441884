import { Component, EventEmitter, Inject, Output } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ServiceConfig } from '../../models/service-config.model';

export class SelectableService extends ServiceConfig {
  selected: boolean;
}
@Component({
  selector: 'hmt-select-services-drawer',
  templateUrl: './select-services-drawer.component.html',
  styleUrl: './select-services-drawer.component.scss',
})
export class SelectServicesDrawerComponent {
  @Output() selectedServices = new EventEmitter();

  selectableServices: SelectableService[] = [];

  constructor(
    private dialogRef: MatDialogRef<SelectServicesDrawerComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      serviceConfigs: ServiceConfig[];
    }
  ) {
    if (data && data.serviceConfigs) {
      this.transformToSelectableServices();
    }
  }

  transformToSelectableServices(): void {
    this.selectableServices = this.data.serviceConfigs.map(service => {
      return {
        ...service,
        selected: true,
      };
    });
  }

  markAsChecked(service, checked) {
    service.selected = checked;
  }

  close(): void {
    this.dialogRef.close();
  }

  save(): void {
    const selectedServices = this.selectableServices.filter(service => service.selected);
    this.selectedServices.emit(selectedServices);
    this.dialogRef.close();
  }
}
