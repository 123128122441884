import { Component, Input, OnInit } from '@angular/core';
import { OrganizationInfo } from '@shared/models/organization-info.model';
import { PreSignedUrl } from '@shared/models/pre-signed-url.model';
import { FileHandlerService } from '@shared/services/file-handler.service';
import { Observable, take } from 'rxjs';

@Component({
  selector: 'hmt-party-details',
  templateUrl: './party-details.component.html',
  styleUrl: './party-details.component.scss',
})
export class PartyDetailsComponent implements OnInit {
  @Input() party: OrganizationInfo;
  @Input() componentType: string;
  logo: Observable<PreSignedUrl>;

  constructor(private fileHandlerService: FileHandlerService) {}

  ngOnInit(): void {
    if (this.party?.logo) {
      this.logo = this.fetchLogo(this.party?.logo);
    }
  }

  fetchLogo(logoUrl: string): Observable<PreSignedUrl> {
    return this.fileHandlerService.fetchFile(logoUrl).pipe(take(1));
  }
}
